import { IGraphQlResponse } from "../Interfaces/IGraphQlResponse";
import { ITaskFilters } from "../Interfaces/ITaskFIlters";
import { ITaskInstance } from "../Interfaces/ITaskInstance";
import { useClient } from "./useClient";
import { useGraphQL, gql } from "./useGraphQL";

interface JsonPatch {
  op: "replace" | "add" | "remove";
  path: string;
  value: any;
}

export function useTasksService(): {
  get: (pageSize: number, filters: ITaskFilters) => Promise<IGraphQlResponse>;
  update: (taskId: string, jsonPatch: JsonPatch) => Promise<ITaskInstance>;
  updateState: (
    taskId: string,
    state: { key: string; value: string }[],
  ) => Promise<ITaskInstance>;
  updateStatus: (taskId: string, status: string) => Promise<ITaskInstance>;
  updateAssignee: (
    taskId: string,
    assigneeId: string,
  ) => Promise<ITaskInstance>;
} {
  const client = useClient();
  const { makeQuery } = useGraphQL();

  async function get(
    pageSize: number,
    filters: ITaskFilters,
  ): Promise<IGraphQlResponse> {
    const query = gql`
      query {
        tasks(
          filter: [
            { property: "filters", condition: NONE_EQ, value: "false" }
            ${
              filters.subjectId
                ? `{ property: "subjectId", condition: EQ, value: "${filters.subjectId}" }`
                : ""
            }
            ${
              filters.assigneeId
                ? `{ property: "assigneeId", condition: EQ, value: "${filters.assigneeId}" }`
                : ""
            }
            ${
              filters.taskStatuses && filters.taskStatuses.length > 0
                ? `{ property: "activityContext.status", condition: EQ, value: [${filters.taskStatuses.map((status) => `"${status}"`).join(",")}] }`
                : ""
            }
            ${
              filters.taskType
                ? `{ property: "taskType", condition: EQ, value: "${filters.taskType}" }`
                : ""
            }
            ${
              filters.correlationId
                ? `{ property: "correlationId", condition: EQ, value: "${filters.correlationId}" }`
                : ""
            }
            ${
              filters.workflowDefinitionKey
                ? `{ property: "workflowDefinitionKey", condition: EQ, value: "${filters.workflowDefinitionKey}" }`
                : ""
            }
            ${
              filters.workflowDefinitionVersion
                ? `{ property: "workflowDefinitionVersion", condition: EQ, value: "${filters.workflowDefinitionVersion}" }`
                : ""
            }
            ${
              filters.startDate
                ? `{ property: "updated", condition: GT, value: "${filters.startDate.toISOString()}" }`
                : ""
            }
            ${
              filters.endDate
                ? `{ property: "updated", condition: LT, value: "${filters.endDate.toISOString()}" }`
                : ""
            }
          ]
          sort: { property: "updated", direction: DESCENDING }
          paging: { pageNumber: 1, pageSize: ${pageSize} }
        ) {
          nodes {
            id
            name
            description
            activityContext {
              status
              ... on ManualActivityContext {
                due
              }
              ...on EmailActivityContext {
                to
              }
              ...on HttpActivityContext {
                url
              }
            }
            assigneeId
            subjectId
            correlationId
            state {
              key
              value
            }
            taskType
            updated
            created
          }
        }
      }
    `;

    const queryResult = await makeQuery(query);
    return queryResult as IGraphQlResponse;
  }

  async function update(
    taskId: string,
    jsonPatch: JsonPatch,
  ): Promise<ITaskInstance> {
    const task: any = await client.$patch(
      ["Api/Tasks/{taskId}", { taskId }],
      [jsonPatch],
    );

    task.status = task.status ?? task.activityContext.status;
    task.due = task.due ?? task.activityContext.due;

    return task as ITaskInstance;
  }

  async function updateState(
    taskId: string,
    state: { key: string; value: string }[],
  ): Promise<ITaskInstance> {
    const stateObject: any = {};
    state.forEach((s) => (stateObject[s.key] = s.value));
    return await update(taskId, {
      op: "replace",
      path: "/state",
      value: stateObject,
    });
  }

  async function updateStatus(
    taskId: string,
    status: string,
  ): Promise<ITaskInstance> {
    return await update(taskId, {
      op: "replace",
      path: "/activityContext/status",
      value: status,
    });
  }

  async function updateAssignee(
    taskId: string,
    assigneeId: string,
  ): Promise<ITaskInstance> {
    return await update(taskId, {
      op: "replace",
      path: "/assigneeId",
      value: assigneeId,
    });
  }

  return { get, update, updateState, updateStatus, updateAssignee };
}
