import moment, { Moment } from "moment";

export const isValidDateTime = (value: string): boolean => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

export const formatLiquidDate = (input: string): string => {
  if (!isValidDateTime(input)) return "";

  const date = moment(input);

  return date.format("YYYY-MM-DDTHH:mm:ss");
};

export const formatUIDate = (input: string | Moment): string => {
  const date = moment(input);

  return date.format("DD-MMM-YYYY HH:mm:ss");
};
