import React, { useState, useEffect } from "react";
import { Paper, IconButton } from "@mui/material";
import { UseEventLogService } from "../../Common/Hooks/useEventLogService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DataTable, Column } from "../Tables/DataTable";
import { XmlEditor } from "../Editors";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import { EventStatus } from "../../Common/Enums/EventStatus";
import EventStatusIcon from "./EventStatusIcon";
import IPagination from "../../Common/Interfaces/IPagination";
import IPaged from "../../Common/Interfaces/IPaged";
import IEventLog from "../../Common/Interfaces/IEventLog";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";
import SnackbarAlert from "../SnackbarAlert";
import { formatUIDate } from "../../Common/Helpers/DateHelper";

interface EventLogRow {
  id: string;
  event: string;
  routingKey?: string;
  subjectId?: string;
  correlationId?: string;
  date: string;
  taskKey?: string;
  eventStatus: EventStatus;
  workflowKey: string;
  context?: string | null;
  owner?: string;
}

const columns: Column<EventLogRow>[] = [
  {
    Header: "",
    accessor: "eventStatus",
    width: 30,
    Cell: ({ value }) => <EventStatusIcon eventStatus={value} />,
  },
  {
    Header: "Event",
    accessor: "event",
  },
  {
    Header: "Task / Routing Key",
    accessor: "taskKey",
    Cell: ({ row: { original } }) => {
      const key = original.taskKey
        ? original.taskKey
        : (original.routingKey?.split(".")[1] ?? "");
      return Wrapped(key);
    },
  },
  {
    Header: "Workflow Key",
    accessor: "workflowKey",
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Subject ID",
    accessor: "subjectId",
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Correlation ID",
    accessor: "correlationId",
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Owner",
    accessor: "owner",
    Cell: ({ value }) => Wrapped(value),
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) => <span>{formatUIDate(value)}</span>,
  },
  {
    id: "expander",
    width: 40,
    Cell: ({ row }: { row: any }) => {
      const styles =
        row.original.context != null
          ? { cursor: "pointer" }
          : { cursor: "not-allowed", color: "#d9d9d9" };
      const props =
        row.original.context != null ? row.getToggleRowExpandedProps() : null;
      const title =
        row.original.context != null
          ? "Toggle Row Expanded"
          : "This event has no context.";

      return (
        <span {...props}>
          <IconButton style={{ ...styles, padding: 4 }} title={title}>
            {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </span>
      );
    },
  },
];

const EventLog = (): JSX.Element => {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pageSize: 10,
    filter: undefined,
  });
  const eventService = UseEventLogService();
  const [logs, setLogs] = useState<IPaged<IEventLog>>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [eventLogFetchStatus, setEventLogFetchStatus] = useState<HttpStatus>(
    HttpStatus.Initial,
  );

  async function fetchLogs(
    pageSize: number,
    pageNum: number,
    filter: string | undefined,
  ) {
    setLoading(true);
    let httpStatus = HttpStatus.Initial;
    try {
      const response = await eventService.$get(pageSize, pageNum, filter);
      setLogs(response);
      httpStatus = HttpStatus.Success;
    } catch (e) {
      httpStatus = HttpStatus.Failure;
    }
    setEventLogFetchStatus(httpStatus);
    setLoading(false);
  }

  const getEmptyDataSourceMessage = (): string => {
    switch (eventLogFetchStatus) {
      case HttpStatus.Success:
        return "No event logs to display.";
      case HttpStatus.Failure:
        return "Error fetching event logs, please try again.";
      default:
        return "";
    }
  };

  const mapLogs = (logs: IPaged<IEventLog>): EventLogRow[] => {
    return logs.items.map((item) => {
      return {
        id: item.id,
        event: item.routingKey?.split(".")[0] ?? "",
        routingKey: item.routingKey,
        correlationId: item.correlationId,
        date: item.created,
        subjectId: item.subjectId,
        taskKey: item.taskKey,
        eventStatus: item.eventStatus,
        workflowKey: item.workflowKey,
        context: item.context,
        owner: item.owner,
      };
    });
  };

  const handleDeleteContexts = async () => {
    setLoading(true);
    await eventService
      .$deleteContexts()
      .then(() => {
        logs?.items.forEach((i) => (i.context = null));
        setLogs(logs);
        setSnackbarMessage("Event contexts successfully deleted.");
      })
      .catch(() => {
        setSnackbarMessage("Error: Failed to delete event contexts.");
      });
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs(pagination.pageSize, pagination.page, pagination.filter);

    // Cleanup
    return () => {
      setLogs(undefined);
      setEventLogFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, [pagination]);

  const renderDetail = (row: EventLogRow) => {
    return <XmlEditor readOnly value={row.context ?? undefined} />;
  };

  return (
    <Paper elevation={4} className={classes.table}>
      <Loading visible={loading} />
      <SnackbarAlert
        open={!!snackbarMessage}
        onClose={() => setSnackbarMessage("")}
        colour={snackbarMessage.includes("Error:") ? "error" : "success"}
        message={snackbarMessage}
      />
      <DataTable
        data={logs?.items ? mapLogs(logs) : []}
        total={logs ? logs.totalCount : 0}
        columns={columns}
        skipPageReset={null}
        initialState={{ hiddenColumns: ["routingKey"] }}
        renderDetail={renderDetail}
        pagination={pagination}
        setPagination={setPagination}
        name="Event Log"
        aria-label="Event Log"
        emptyDataSourceMessage={getEmptyDataSourceMessage()}
        footerAction={
          logs && logs.totalCount > 0 ? handleDeleteContexts : undefined
        }
        footerActionLabel={
          logs && logs.totalCount > 0 ? "Delete Event Contexts" : undefined
        }
      />
    </Paper>
  );
};

export default EventLog;
