import { useEffect, useState } from "react";
import "../../Common/Helpers/Extensions/string.extensions";
import ITableOperationalOverview from "../../Common/Interfaces/ITableOperationalOverview";
import { useTasksService } from "../../Hooks/useTasksService";
import Tasks from "../Tasks";
import SnackbarAlert from "../SnackbarAlert";
import Loading from "../Loading/Loading";
import { StatusServicesContextProvider } from "../../Services/TaskStatusServiceContext";
import { classes } from "../../App.Styles";
import { ITaskInstance } from "../../Interfaces/ITaskInstance";
import { TaskStatus } from "../../Common/Enums/TaskStatus";
import { Moment } from "moment";

interface IProps {
  row: ITableOperationalOverview;
  taskStatuses: TaskStatus[];
  onTasksChange: () => void;
  startDateFilter: Moment | null;
  endDateFilter: Moment | null;
}

const OperationalOverviewDetail: React.FC<IProps> = ({
  row,
  taskStatuses,
  onTasksChange,
  startDateFilter,
  endDateFilter,
}) => {
  const [instances, setInstances] = useState<ITaskInstance[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    getInstances();
  }, [taskStatuses]);

  const { get } = useTasksService();

  const getInstances = async (callOnTasksChange = false) => {
    setLoading(true);
    const filters = {
      workflowDefinitionKey: row.key,
      workflowDefinitionVersion: row.version,
      taskStatuses: taskStatuses,
      startDate: startDateFilter,
      endDate: endDateFilter,
    };
    const gqlQueryResult = await get(0, filters);
    if (gqlQueryResult.tasks) {
      setInstances(gqlQueryResult.tasks.nodes);

      if (callOnTasksChange) {
        onTasksChange();
      }
    } else if (
      gqlQueryResult.response?.errors.filter((e) => e.extensions.message)
    ) {
      setError(
        gqlQueryResult.response?.errors
          .map((e) => e.extensions.message)
          .join("\n"),
      );
    } else if (gqlQueryResult.response?.errors) {
      setError(gqlQueryResult.response.errors.map((e) => e.message).join("\n"));
    } else {
      setError("An unknown error occurred while loading tasks");
    }

    setLoading(false);
  };

  return (
    <div>
      {error && (
        <SnackbarAlert
          onClose={() => setError(undefined)}
          colour="error"
          message={error || ""}
        />
      )}
      <Loading visible={loading} />
      <StatusServicesContextProvider>
        <div className={classes.overviewTasksContainer}>
          <Tasks tasks={instances} onChange={() => getInstances(true)} />
        </div>
      </StatusServicesContextProvider>
    </div>
  );
};

export default OperationalOverviewDetail;
