import React, { useEffect, useState } from "react";
import { ISubjectTasks } from "../Interfaces/ISubjectTasks";
import { Button, Grid, Paper, Typography } from "@mui/material";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import ContractIcon from "@mui/icons-material/ExpandLess";
import classNames from "classnames";
import { classes } from "../App.Styles";
import { TaskStatus } from "../Common/Enums/TaskStatus";
import { TaskStatusNameProvider } from "../Common/Providers/TaskStatusNameProvider";
import Tasks from "./Tasks";

interface IProps {
  subjectTasks: ISubjectTasks;
  onChange: () => void;
}

const SubjectView: React.FC<IProps> = ({ subjectTasks, onChange }) => {
  const [tasksOpen, setTasksOpen] = useState(false);
  const taskButtonId = `tasks-${subjectTasks.subjectId}`;
  const [newCount, setNewCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [blockedCount, setBlockedCount] = useState(0);
  const [cancelledCount, setCancelledCount] = useState(0);

  const countByStatus = (taskStatus: TaskStatus) => {
    return subjectTasks.tasks.filter(
      (t) => t.activityContext.status === taskStatus,
    ).length;
  };
  const updateCounts = () => {
    setNewCount(countByStatus(TaskStatus.New));
    setInProgressCount(countByStatus(TaskStatus.InProgress));
    setCompletedCount(countByStatus(TaskStatus.Completed));
    setFailedCount(countByStatus(TaskStatus.Failed));
    setBlockedCount(countByStatus(TaskStatus.Blocked));
    setCancelledCount(countByStatus(TaskStatus.Cancelled));
  };

  useEffect(() => {
    updateCounts();
  }, [subjectTasks]);

  const renderStatusLabel = (taskStatus: TaskStatus, count: number) => {
    return (
      <span style={{ paddingRight: 8, paddingLeft: 8 }}>
        {TaskStatusNameProvider(taskStatus)} <b>({count})</b>
      </span>
    );
  };

  const titleClass = tasksOpen
    ? classes.subjectTitleOpen
    : classes.subjectTitleBottom;

  return (
    <Paper elevation={2} className={classes.tasksContainer}>
      <Grid container className={classNames(classes.subjectTitle, titleClass)}>
        <Grid item xs={3}>
          <Typography variant="h6">{subjectTasks.subjectId}</Typography>
        </Grid>
        <Grid item container alignContent="center" xs={6}>
          <Typography variant="body1">
            {renderStatusLabel(TaskStatus.New, newCount)}
            {renderStatusLabel(TaskStatus.InProgress, inProgressCount)}
            {renderStatusLabel(TaskStatus.Completed, completedCount)}
            {renderStatusLabel(TaskStatus.Cancelled, cancelledCount)}
            {renderStatusLabel(TaskStatus.Failed, failedCount)}
            {renderStatusLabel(TaskStatus.Blocked, blockedCount)}
          </Typography>
        </Grid>
        <Grid container xs={3} justifyContent="flex-end" item>
          <Button
            className={classNames(classes.subjectTitle, titleClass)}
            onClick={() => setTasksOpen(!tasksOpen)}
            id={taskButtonId}
          >
            Tasks {tasksOpen ? <ContractIcon /> : <ExpandIcon />}
          </Button>
        </Grid>
      </Grid>
      {tasksOpen && <Tasks tasks={subjectTasks.tasks} onChange={onChange} />}
    </Paper>
  );
};

export default SubjectView;
