import { Grid, Tooltip, Typography, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { classes } from "../App.Styles";

const TaskBody = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

interface IProps {
  label: string | JSX.Element;
  body: string | JSX.Element | null;
  icon: JSX.Element;
}

const TaskInfo: React.FC<IProps> = ({ label, body, icon }) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isOverflowedFunc = () => {
      const contentElement = contentRef.current;
      if (contentElement) {
        return (
          contentElement.scrollHeight > contentElement.clientHeight ||
          contentElement.scrollWidth > contentElement.clientWidth
        );
      }
      return false;
    };

    setIsOverflowed(isOverflowedFunc());
  }, [body]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.taskItem}
      spacing={1}
    >
      <Grid item xs={1.25}>
        {icon}
      </Grid>
      <Grid item xs={10.75}>
        <Typography className={classes.taskHeading}>{label}</Typography>
        <Tooltip title={isOverflowed ? body : ""}>
          <TaskBody
            className={body ? classes.taskBody : classes.taskBodyPlaceholder}
            ref={contentRef}
          >
            {body ? body : "None"}
          </TaskBody>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default TaskInfo;
