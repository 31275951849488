import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TableCell,
  TableFooter,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DataTable, Column } from "../Tables/DataTable";
import Wrapped from "../../Common/Helpers/WrappedTextHelpers";
import { HttpStatus } from "../../Common/Enums/HttpStatus";
import Loading from "../Loading/Loading";
import { classes } from "../../App.Styles";
import { useOperationalOverviewService } from "../../Common/Hooks/useOperationalOverviewService";
import { CellProps, ColumnInstance, Row } from "react-table";
import OperationalOverviewDetail from "./OperationalOverviewDetail";
import ITableOperationalOverview from "../../Common/Interfaces/ITableOperationalOverview";
import IOperationalOverviewResult from "../../Common/Interfaces/IOperationalOverviewResult";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { TaskStatus } from "../../Common/Enums/TaskStatus";
import { useAbility } from "../Authorisation/AbilityContext";
import { formatUIDate } from "../../Common/Helpers/DateHelper";
import { Moment } from "moment";
import moment from "moment";
import OverviewCharts from "./OverviewCharts";

type TaskStatusString =
  | "new"
  | "inProgress"
  | "completed"
  | "cancelled"
  | "failed"
  | "blocked";

type TaskStatusOrTotal = TaskStatusString | "total";

interface IWorkflowTaskStatuses {
  workflowKey: string;
  workflowVersion: string;
  taskStatuses: TaskStatus[];
}

interface ISummaryTableRow {
  id: string;
  key: string;
  version: string;
  lastActivity: string;
  new: string;
  inProgress: string;
  completed: string;
  cancelled: string;
  failed: string;
  blocked: string;
  total: string;
}

const statusMapping = {
  new: TaskStatus.New,
  inProgress: TaskStatus.InProgress,
  failed: TaskStatus.Failed,
  completed: TaskStatus.Completed,
  cancelled: TaskStatus.Cancelled,
  blocked: TaskStatus.Blocked,
};
const allStatuses = Object.values(statusMapping);
const Totals = "Totals";
const total = "total";

const OperationalOverview = (): JSX.Element => {
  const ability = useAbility();
  const canViewTasks = ability.can("view", "task");
  const service = useOperationalOverviewService();
  const [operationalOverview, setOperationalOverview] =
    useState<IOperationalOverviewResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const [startDateFilter, setStartDateFilter] = useState<Moment | null>(
    moment().startOf("day").subtract(6, "days"),
  );
  const [endDateFilter, setEndDateFilter] = useState<Moment | null>(null);

  const setDateFilter = useCallback(
    (start: Moment | null, end: Moment | null) => {
      setStartDateFilter(start);
      setEndDateFilter(end);
    },
    [],
  );

  const [operationalOverviewFetchStatus, setOperationalOverviewFetchStatus] =
    useState<HttpStatus>(HttpStatus.Initial);
  const [workflowTaskStatuses, setWorkflowTaskStatuses] = useState<
    IWorkflowTaskStatuses[]
  >([]);

  const toggleTaskStatusSelection = (
    workflowKey: string,
    workflowVersion: string,
    taskStatusOrTotal: TaskStatusOrTotal,
  ) => {
    setWorkflowTaskStatuses((prevStatuses) => {
      const existingEntry = prevStatuses.find(
        (wts) =>
          wts.workflowKey === workflowKey &&
          wts.workflowVersion === workflowVersion,
      );

      const updatedTaskStatuses =
        taskStatusOrTotal === total
          ? allStatuses
          : handleStatusClicked(existingEntry, taskStatusOrTotal);

      return existingEntry
        ? prevStatuses.map((wts) =>
            wts.workflowKey === workflowKey &&
            wts.workflowVersion === workflowVersion
              ? { ...wts, taskStatuses: updatedTaskStatuses }
              : wts,
          )
        : [
            ...prevStatuses,
            { workflowKey, workflowVersion, taskStatuses: updatedTaskStatuses },
          ];
    });
  };

  const handleStatusClicked = (
    existingEntry: IWorkflowTaskStatuses | undefined,
    taskStatusOrTotal: TaskStatusString,
  ) => {
    const taskStatus = statusMapping[taskStatusOrTotal];
    if (existingEntry && existingEntry.taskStatuses !== allStatuses) {
      const isStatusSelected = existingEntry.taskStatuses.includes(taskStatus);
      const updatedTaskStatuses = [...existingEntry.taskStatuses, taskStatus];

      return isStatusSelected
        ? existingEntry.taskStatuses.filter((status) => status !== taskStatus)
        : updatedTaskStatuses.length === allStatuses.length
          ? allStatuses
          : updatedTaskStatuses;
    }

    return [taskStatus];
  };

  const renderButtonCell =
    (taskStatusOrTotal: TaskStatusOrTotal) =>
    ({ value, row }: CellProps<ISummaryTableRow, string>): JSX.Element => {
      const onClick = () =>
        toggleTaskStatusSelection(
          row.original.key,
          row.original.version,
          taskStatusOrTotal,
        );

      const taskStatuses = workflowTaskStatuses.find(
        (wts) =>
          wts.workflowKey === row.original.key &&
          wts.workflowVersion === row.original.version,
      )?.taskStatuses;

      const isTotalSelected =
        !taskStatuses ||
        taskStatuses.length === 0 ||
        taskStatuses.length === allStatuses.length;

      const isStatusSelected =
        taskStatusOrTotal !== total &&
        taskStatuses?.includes(statusMapping[taskStatusOrTotal]);

      const contained = "contained";
      const outlined = "outlined";

      const variant = row.isExpanded
        ? isTotalSelected
          ? taskStatusOrTotal === total
            ? contained
            : outlined
          : isStatusSelected
            ? contained
            : outlined
        : "text";

      return row.isExpanded ? (
        <Button
          variant={variant}
          color={"secondary"}
          onClick={onClick}
          fullWidth
        >
          {Wrapped(value)}
        </Button>
      ) : (
        <Box textAlign={"center"} width={"100%"}>
          {Wrapped(value)}
        </Box>
      );
    };

  const renderHeader = (
    header: string,
    column: ColumnInstance<ISummaryTableRow>,
    center = true,
  ) => (
    <div style={{ textAlign: center ? "center" : "left" }}>
      {header}
      <TableSortLabel
        style={{ display: column.isSorted ? "inline-flex" : "none" }}
        active={column.isSorted}
        direction={column.isSortedDesc ? "desc" : "asc"}
      />
    </div>
  );

  const renderTaskCountColumn = (
    accessor: TaskStatusOrTotal,
  ): Column<ISummaryTableRow> => {
    const headerText = accessor
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());

    return {
      Header: ({ column }) => renderHeader(headerText, column),
      accessor: accessor,
      width: countWidth,
      Cell: renderButtonCell(accessor),
    };
  };

  const countWidth = 110;
  const actionsWidth = 90;
  const columns: Column<ISummaryTableRow>[] = [
    {
      Header: ({ column }) => renderHeader("Workflow Key", column, false),
      accessor: "id",
      Cell: ({ value }) => Wrapped(value),
    },
    {
      Header: ({ column }) => renderHeader("Last Activity", column, false),
      accessor: "lastActivity",
      Cell: ({ value }) => Wrapped(formatUIDate(value)),
    },
    renderTaskCountColumn("new"),
    renderTaskCountColumn("inProgress"),
    renderTaskCountColumn("failed"),
    renderTaskCountColumn("completed"),
    renderTaskCountColumn("cancelled"),
    renderTaskCountColumn("blocked"),
    renderTaskCountColumn(total),
    ...(canViewTasks
      ? [
          {
            Header: "Actions",
            id: "actions",
            width: actionsWidth,
            Cell: ({ row }: { row: Row<ISummaryTableRow> }) => {
              return (
                <span {...row.getToggleRowExpandedProps()}>
                  <IconButton className={classes.mediumGrey}>
                    {row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </span>
              );
            },
          },
        ]
      : []),
  ];
  const fetchOperationalOverview = async () => {
    setLoading(true);
    try {
      const response = await service.$get(startDateFilter, endDateFilter);
      setOperationalOverview(response);
      setOperationalOverviewFetchStatus(HttpStatus.Success);
    } catch {
      setOperationalOverviewFetchStatus(HttpStatus.Failure);
    }
    setLoading(false);
  };

  const getEmptyDataSourceMessage = (): string => {
    switch (operationalOverviewFetchStatus) {
      case HttpStatus.Success:
        return "No task counts to display.";
      case HttpStatus.Failure:
        return "Error fetching task counts, please try again.";
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchOperationalOverview();
    return () => {
      setOperationalOverview(undefined);
      setOperationalOverviewFetchStatus(HttpStatus.Initial);
      setLoading(true);
    };
  }, [startDateFilter, endDateFilter]);

  const processTableData = (): ITableOperationalOverview[] => {
    if (!operationalOverview) {
      return [];
    }

    const { workflowDefinitions = [] } = operationalOverview;
    const totals: ITableOperationalOverview[] = [];

    workflowDefinitions.forEach(({ key, versions }) => {
      versions.forEach(({ version, taskCounts, lastActivity }) => {
        totals.push({
          id: `${key} (v${version})`,
          key,
          version,
          lastActivity,
          ...taskCounts,
        });
      });
    });

    return totals.sort((a, b) => a.id.localeCompare(b.id));
  };

  const tableData = useMemo(processTableData, [operationalOverview]);

  const onTaskChange = async () => {
    await fetchOperationalOverview();
  };

  const renderDetail = (row: ITableOperationalOverview) => {
    if (!canViewTasks) return <></>;
    const wts = workflowTaskStatuses.find(
      (wts) =>
        wts.workflowKey === row.key &&
        wts.workflowVersion == row.version.toString(),
    );

    return (
      <OperationalOverviewDetail
        row={row}
        taskStatuses={wts?.taskStatuses ?? ([] as TaskStatus[])}
        onTasksChange={onTaskChange}
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
      />
    );
  };

  const footerCell = (element: JSX.Element, width = 150) => {
    return (
      <TableCell
        width={width}
        style={{
          flex: `${width} 0 auto`,
          fontSize: "0.875rem",
          color: "black",
        }}
      >
        <Box display="flex" alignItems="center" height="100%">
          {element}
        </Box>
      </TableCell>
    );
  };

  const taskCountFooterCell = (count: number | undefined) => {
    return footerCell(
      <Box textAlign={"center"} width={"100%"}>
        {Wrapped((count ?? 0).toString(), true)}
      </Box>,
      countWidth,
    );
  };

  const renderTableFooter = useCallback((rows: ITableOperationalOverview[]) => {
    const taskCounts = {
      new: rows.reduce((sum, current) => sum + current.new, 0),
      inProgress: rows.reduce((sum, current) => sum + current.inProgress, 0),
      completed: rows.reduce((sum, current) => sum + current.completed, 0),
      failed: rows.reduce((sum, current) => sum + current.failed, 0),
      blocked: rows.reduce((sum, current) => sum + current.blocked, 0),
      cancelled: rows.reduce((sum, current) => sum + current.cancelled, 0),
      total: rows.reduce((sum, current) => sum + current.total, 0),
    };

    return (
      <TableFooter>
        <TableRow style={{ display: "flex" }}>
          {footerCell(Wrapped(Totals, true))}
          {footerCell(Wrapped("", true))}
          {taskCountFooterCell(taskCounts.new)}
          {taskCountFooterCell(taskCounts.inProgress)}
          {taskCountFooterCell(taskCounts.failed)}
          {taskCountFooterCell(taskCounts.completed)}
          {taskCountFooterCell(taskCounts.cancelled)}
          {taskCountFooterCell(taskCounts.blocked)}
          {taskCountFooterCell(taskCounts.total)}
          <TableCell
            width={actionsWidth}
            height={73}
            style={{ flex: `${actionsWidth} 0 auto` }}
          />
        </TableRow>
      </TableFooter>
    );
  }, []);

  const renderCharts = useCallback(
    (rows: ITableOperationalOverview[]) => <OverviewCharts tableData={rows} />,
    [],
  );

  const customGlobalFilter = (
    rows: any[],
    columnIds: string[],
    filterValue: string,
  ): any[] => {
    if (!filterValue) {
      return rows;
    }
    return rows.filter((row) => {
      const workflowKeyValue = row.values[columnIds[0]] || "";
      const lastActivityValue = formatUIDate(row.values[columnIds[1]]) || "";
      return (
        workflowKeyValue.toLowerCase().includes(filterValue.toLowerCase()) ||
        lastActivityValue.toLowerCase().includes(filterValue.toLowerCase())
      );
    });
  };

  return (
    <Paper elevation={4} className={classes.table}>
      <Loading visible={loading} />
      <DataTable
        data={tableData}
        columns={columns}
        skipPageReset={true}
        renderDetail={renderDetail}
        name="Task Counts"
        aria-label="Task Counts"
        emptyDataSourceMessage={getEmptyDataSourceMessage()}
        setDateFilter={setDateFilter}
        renderTableFooter={renderTableFooter}
        renderPreHeader={renderCharts}
        total={tableData.length}
        customGlobalFilter={customGlobalFilter}
      />
    </Paper>
  );
};

export default OperationalOverview;
