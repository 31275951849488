import { useMemo } from "react";
import { Grid } from "@mui/material";
import ITableOperationalOverview from "../../Common/Interfaces/ITableOperationalOverview";
import { BarChart, ChartsColorPalette, PieChart } from "@mui/x-charts";
import { IReadOnlyComponentProps } from "../../Interfaces/IReadOnlyComponentProps";
import ITaskCounts from "../../Common/Interfaces/ITaskCounts";
import theme from "../../App/App.Theme";
import { blue, orange, red, yellow } from "@mui/material/colors";

interface IProps extends IReadOnlyComponentProps {
  tableData?: ITableOperationalOverview[];
}

const colors: ChartsColorPalette = [
  blue[600],
  theme.palette.secondary.main,
  theme.palette.primary.main,
  red[900],
  orange[800],
  yellow[700],
];

const OverviewCharts: React.FC<IProps> = ({ tableData }): JSX.Element => {
  const statusGraphData = useMemo(() => {
    if (!tableData) return [{ data: [] }];

    return [
      {
        data: [
          {
            id: 0,
            value: tableData.reduce((sum, current) => sum + current.new, 0),
            label: "New",
          },
          {
            id: 1,
            value: tableData.reduce(
              (sum, current) => sum + current.inProgress,
              0,
            ),
            label: "In Progress",
          },
          {
            id: 2,
            value: tableData.reduce(
              (sum, current) => sum + current.completed,
              0,
            ),
            label: "Completed",
          },
          {
            id: 3,
            value: tableData.reduce((sum, current) => sum + current.failed, 0),
            label: "Failed",
          },
          {
            id: 4,
            value: tableData.reduce((sum, current) => sum + current.blocked, 0),
            label: "Blocked",
          },
          {
            id: 5,
            value: tableData.reduce(
              (sum, current) => sum + current.cancelled,
              0,
            ),
            label: "Cancelled",
          },
        ],
      },
    ];
  }, [tableData]);

  const workflowGraphData = useMemo(() => {
    if (!tableData) return [];

    return tableData.map((x) => {
      return {
        workflow: x.id,
        new: x.new,
        inProgress: x.inProgress,
        completed: x.completed,
        failed: x.failed,
        blocked: x.blocked,
        cancelled: x.cancelled,
      };
    });
  }, [tableData]);

  return (
    <Grid container margin={4}>
      <Grid item xs={6} md={3}>
        <PieChart
          series={statusGraphData}
          title="Tasks by Status"
          colors={colors}
          margin={{ left: 150, right: 20 }}
          slotProps={{
            legend: {
              direction: "column",
              position: { vertical: "middle", horizontal: "left" },
            },
          }}
        />
      </Grid>
      <Grid item xs={6} md={9}>
        <BarChart
          dataset={workflowGraphData}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "workflow",
              tickLabelStyle: {
                angle: 45,
                textAnchor: "start",
              },
            },
          ]}
          slotProps={{ legend: { hidden: true } }}
          series={[
            { dataKey: "new", label: "New" },
            { dataKey: "inProgress", label: "In Progress" },
            { dataKey: "completed", label: "Completed" },
            { dataKey: "failed", label: "Failed" },
            { dataKey: "blocked", label: "Blocked" },
            { dataKey: "cancelled", label: "Cancelled" },
          ]}
          height={275}
          title="Workflows by Status"
          colors={colors}
        />
      </Grid>
    </Grid>
  );
};

export default OverviewCharts;
