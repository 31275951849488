import { Moment } from "moment";
import { useClient } from "../../Hooks/useClient";
import IOperationalOverviewResult from "../Interfaces/IOperationalOverviewResult";

export function useOperationalOverviewService(): {
  $get: (
    start: Moment | null,
    end: Moment | null,
  ) => Promise<IOperationalOverviewResult>;
} {
  const client = useClient();

  async function $get(
    start: Moment | null,
    end: Moment | null,
  ): Promise<IOperationalOverviewResult> {
    const params: any = {};

    if (start) params["start"] = start?.toISOString();
    if (end) params["end"] = end?.toISOString();

    return (await client.$get([
      `/Api/OperationalOverview`,
      params,
    ])) as IOperationalOverviewResult;
  }

  return { $get };
}
