import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import FilterBase from "./FilterBase";
import { TaskStatusNameProvider } from "../../Common/Providers/TaskStatusNameProvider";
import { TaskStatus } from "../../Common/Enums/TaskStatus";

interface IProps {
  taskStatus?: TaskStatus;
  name: string;
  tooltip: string;
  onChange: (status: TaskStatus | undefined) => void;
}

const TaskStatusFilter: React.FC<IProps> = ({
  taskStatus,
  name,
  tooltip,
  onChange,
}) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const onDelete = () => {
    onChange(undefined);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchor(null);
  };

  const handleChange = (value: TaskStatus) => {
    onChange(value);
    handleClose();
  };

  const renderMenuItem = (status: TaskStatus) => {
    return (
      <MenuItem onClick={() => handleChange(status)}>
        {TaskStatusNameProvider(status)}
      </MenuItem>
    );
  };

  return (
    <>
      <FilterBase
        value={taskStatus ? TaskStatusNameProvider(taskStatus) : undefined}
        name={name}
        onClick={handleClick}
        onDelete={onDelete}
        tooltip={tooltip}
      />
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={!!menuAnchor}
        onClose={handleClose}
      >
        {renderMenuItem(TaskStatus.New)}
        {renderMenuItem(TaskStatus.InProgress)}
        {renderMenuItem(TaskStatus.Completed)}
        {renderMenuItem(TaskStatus.Cancelled)}
        {renderMenuItem(TaskStatus.Blocked)}
        {renderMenuItem(TaskStatus.Failed)}
      </Menu>
    </>
  );
};

export default TaskStatusFilter;
